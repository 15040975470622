import {hydrate} from "@/utils/form";

const moment = require('moment');

export default class Timetable {
  id = null;
  playgrounds = [];
  name = null;
  fromDate = null;
  toDate = null;
  daysOfTheWeek = [];
  serialized = false;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
        this.formatDates();
      }
      if (options.deserialize) {

      }
    }
  }

  serialize(object) {
    hydrate(this, object);
  }


  formatDates() {
    this.fromDate = this.fromDate ? moment(this.fromDate).format('YYYY-MM-DD') : this.fromDate;
    this.toDate = this.toDate ? moment(this.toDate).format('YYYY-MM-DD') : this.toDate;
  }
}
