<template>
  <div :class="classContainer">
    <d-body-top-bar
      :title="$t('views.playground.config')"
      :is-icons-content="false"
    >
      <div slot="tree">
        <label v-if="mobileView" class="inactive-tree mr-1">
          {{ $t('views.playground.your-activities-area') }} /
        </label>
        <label @click="$router.push('/playground')" class="inactive-tree link-area pointer">
          {{ $t('views.playground.all-area') }} /
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ topBarTitle }} </label>
      </div>
      <div slot="icons">
        <steps
          :step="2"
          @on:step-0:selected="$router.push({name: 'playground_update', params:{ id: $route.params.id }})"
          @on:step-1:selected="$router.push({name: 'playground_config-step1', params: { id: $route.params.id }})"
        />
      </div>
    </d-body-top-bar>
    <div class="playground-creation-container">
      <description
        @on:step-2:validated="onNextClick()"
      />
      <b-row class="mt-3">
        <b-col class="pr-0" :cols="6">
          <calendar-list
            :reload="reload"
            @reload:selected-timetables="reloadSelection = !reloadSelection"
          />
        </b-col>
        <b-col
          class="col-0-5 d-flex align-content-center align-items-center justify-content-center"
          align="center"
        >
          <img
            width="50"
            height="50"
            :src="svgIcon"
          />
        </b-col>
        <b-col class="pl-0" :cols="5">
          <calendar-list-selection
            @reload-timetables="reload = !reload"
            :reload-selection="reloadSelection"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Timetable from "@/classes/doinsport/Timetable";
import {putTimetable} from "@api/doinsport/services/timetable/timetable.api";
import {refine} from "@/utils/classes";
import {putClubPlayground} from "@api/doinsport/services/playground/playground.api";

export default {
  components: {
    Description: () => import('./Description'),
    CalendarList: () => import('./CalendarList'),
    CalendarListSelection: () => import('./CalendarListSelection'),
    Steps: () => import('@views/playground/steps/Menus'),
  },
  computed: {
    topBarTitle() {
      if (this.$route.name === 'playground_new') {
        return this.$t('views.playground.config');
      } else {
        return this.$t('views.playground.config');
      }
    },
    mobileView() {
      return this.$store.getters['layout/getInnerWidth'] >= 991;
    }
  },
  data: () => ({
    timetable: new Timetable(),
    reload: false,
    reloadSelection: false,
    svgIcon: require('@/assets/icons/playground/Arrows.svg'),
  }),
  props: {
    classContainer: {
      type: String,
      default: ''
    },
    calendarsListSelection: {
      type: Array,
      default: this
    },
    playgroundTimetables: {
      type: Array,
      default: this
    },
    playground: {
      type: Object,
      default: this
    }
  },
  methods: {
    onNextClick() {
      const count = this.$store.getters["playgrounds/getTimetablesTotalItems"];

      if (count === 0) {
        this.$flash(null, this.$t('views.playground.steps.step-2.empty-calendars'));
      } else {
        let timetablesIris = [];
        for (const timetable of this.$store.getters["playgrounds/getSelectedTimetables"]) {
          timetablesIris.push(timetable.iri);
        }

        putClubPlayground(this.$route.params.id, {timetables: timetablesIris})
          .then((response) => {
            this.$router.push({name: 'playground_config-step3', params: {id: this.$route.params.id}});
          })
        ;
      }
    }
  }
}
</script>
<style scoped>
.playground-creation-container {
  background-color: #F9FAFA;
}

.col-0-5 {
  flex: 0 0 5.533333%;
  max-width: 5.533333%;
}
</style>
