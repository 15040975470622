import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

const URI = "/clubs/playgrounds/timetables";

export const postTimetable = (timetable) => _post(URI, timetable);

export const putTimetable = (timetable) => _put(URI + '/' + timetable.id, timetable);

export const deleteTimetable = (id) => _delete(URI + '/' + id);

export const getTimetables = (itemsPerPage, page) => _get(URI + '?club.id=' + clubId + '&itemsPerPage='  + itemsPerPage + '&page='+ page);

export const getPlaygroundTimetables = (itemsPerPage, page, playgroundId) => _get(URI + '?club.id=' + clubId + '&itemsPerPage=' + itemsPerPage + '&page='+ page + '&playgrounds.id=' + playgroundId);
