import { URL } from "./index";

import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const getPlaygrounds = (itemsPerPage, page, search) => _get(URL + '?club.id=' + clubId + '&itemsPerPage=' + itemsPerPage + '&page=' + page + '&search=' + search);

export const postClubPlayground = (playground) => _post(URL , playground);

export const getClubPlayground = (id) => _get(URL + '/' + id );

export const putClubPlayground = (id, playground) => _put(URL + '/' + id , playground);

export const deleteClubPlayground = (id) => _delete(URL + '/' + id );

export const getPlaygroundList = () => _get(URL + '?club.id=' + clubId + '&itemsPerPage=60');

export const getPlaygroundActivities = (id) => _get(URL + '/' + id + '/activities');

export const getPlayground = (iri) => _get(iri);

export const getPlaygroundMultipleSlots = (playgroundId, date) => _get(`${URL}/${playgroundId}/slots?date=${date}`)
